<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { vue } from '@/main'
import { TYPES as SUPPLIER_CONTACT_TYPES } from '../../../organization/contact/contact/supplier-contact/store'

export default {
  name: 'techniciansMixin',
  props: ['technicians'],
  created () {
    if (!this.technicianList || (this.technicianList && this.technicianList.length === 0)) {
      this.setTechniciansList({})
    }
  },
  data () {
    return {
      form: {}
    }
  },
  computed: {
    ...mapGetters({
      technicianList: SUPPLIER_CONTACT_TYPES.GENERIC.organization.suppliercontact.LIST.GETTERS.list
    }),
    techniciansListOptions () {
      const options = []
      if (this.technicianList && this.technicianList.results && this.technicianList.results.length > 0) {
        this.technicianList.results.forEach((technician) => {
          const option = {
            id: technician.id,
            first_name: technician.first_name,
            last_name: technician.last_name,
            name: `${technician.first_name} ${technician.last_name}`,
            supplier_role_list: technician.supplier_role_list
          }
          if (option.supplier_role_list) {
            option.supplier_role_list = option.supplier_role_list.map((role) => {
              return { id: role, name: role }
            })
          } else {
            option.supplier_role_list = []
          }
          if (this.technicians && this.technicians.length > 0) {
            if (!this.technicians.some((instance) => { return option.id === instance.technician })) {
              options.push(option)
            }
          } else {
            options.push(option)
          }
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setTechniciansList: SUPPLIER_CONTACT_TYPES.GENERIC.organization.suppliercontact.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getTechniciansList: SUPPLIER_CONTACT_TYPES.GENERIC.organization.suppliercontact.LIST.ACTIONS.setFilters
    }),
    onSearchTechnician (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getTechniciansList({ filter__last_name__icontains: search }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setTechniciansList({})
      }
    },
    selectTechnician (item) {
      this.setTechniciansList({})
      const newInstance = {
        technician: item.id,
        technician_first_name: item.first_name,
        technician_last_name: item.last_name,
        technician_supplier_role_list: item.supplier_role_list
      }
      if (this.id && this.form.technician && this.form.technician_first_name) {
        this.$emit('show-delete-modal', { instance: this.form, newInstance: newInstance })
        return
      }
      this.$set(this.form, 'technician', newInstance.technician)
      this.$set(this.form, 'technician_first_name', newInstance.technician_first_name)
      this.$set(this.form, 'technician_last_name', newInstance.technician_last_name)
      this.$set(this.form, 'technician_supplier_role_list', newInstance.technician_supplier_role_list)
    },
    clearTechnician () {
      this.setTechniciansList({})
      if (this.id) {
        this.$emit('show-delete-modal', { instance: this.form })
        return
      }
      this.$set(this.form, 'technician', null)
      this.$set(this.form, 'technician_first_name', null)
      this.$set(this.form, 'technician_last_name', null)
      this.$set(this.form, 'technician_supplier_role_list', null)
    }
  }
}
</script>
