<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll"
      @check-none="checkNone"
      @set-selected-row="setSelectedRow"
      :show-checks="showChecks"
      :showEditButton="showEditButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-edit-form="$emit('open-edit-form', $event)"
    >
      <template slot="roles" slot-scope="row">
        <div v-if="row.item.technician_supplier_role_list">
          <span
            v-for="role in row.item.technician_supplier_role_list"
            :key="role"
            class="tag"
          >
          <span>
            {{ role }}
          </span>
          </span>
        </div>
      </template>
      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn
          variant="outline-primary"
          v-has-perms="deleteRolePerm"
          :title="'Remove technician' | translate"
          @click="showConfirmModal(row.item)"
        >
          <i class="fas fa-times"></i>
        </b-btn>
      </template>
    </thux-table>
    <ConfirmDialog
      v-if="showDeleteModal && technicianToDelete.id"
      ref-id="modal-delete"
      :title="$t('Are you sure to remove this technician?')"
      :button-confirm-text="'Remove' | translate"
      @confirm-dialog-close="closeConfirmModal"
      @confirm-dialog-select="deleteTechnician"
    >
        <template slot="body">
          <b-table
            no-local-sorting
            show-empty
            striped
            hover
            responsive
            :items="[technicianToDelete]"
            :fields="technicianFields"
          >
            <template slot="cell(technician_supplier_role_list)" slot-scope="row">
              <div v-if="row.item.technician_supplier_role_list">
                <span
                  v-for="role in row.item.technician_supplier_role_list"
                  :key="role"
                  class="tag"
                >
                <span>
                  {{ role }}
                </span>
                </span>
              </div>
            </template>
          </b-table>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_TECHNICIAN_TYPES } from '../../yard-technician/store'

import ThuxTableMixin from '../../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import ConfirmDialog from '../../../../components/ConfirmDialog'

export default {
  name: 'YardTechniciansTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  components: { ConfirmDialog },
  data () {
    return {
      technicianFields: [
        {
          key: 'technician_first_name',
          label: this.$t('First name'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'technician_last_name',
          label: this.$t('Last name'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap'
        },
        {
          key: 'technician_supplier_role_list',
          label: this.$t('Roles'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap'
        }
      ],
      showDeleteModal: false,
      technicianToDelete: {},
      fields: [
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'technician__first_name',
          label: this.$t('First name'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'technician_first_name'
        },
        {
          key: 'technician__last_name',
          label: this.$t('Last name'),
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'technician_last_name'
        },
        {
          key: 'roles',
          label: this.$t('Roles'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          custom: true
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'technician__first_name', 'technician__last_name'],
      // Permissions
      deleteRolePerm: 'yard_yardtechnician_destroy'
    }
  },
  computed: {
    ...mapGetters({
      selectAll: YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.LIST.GETTERS.selectAll,
      selectedList: YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return false
    },
    showDetailButton () {
      return false
    },
    showEditButton () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setList: YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.LIST.MUTATIONS.setList,
      setSelectedList: YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.LIST.MUTATIONS.setSelectedList,
      setSelectAll: YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.LIST.ACTIONS,
      delete: YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.DETAIL.ACTIONS.delete
    }),
    showConfirmModal (instance) {
      this.$set(this, 'technicianToDelete', instance)
      this.$set(this, 'showDeleteModal', true)
    },
    closeConfirmModal () {
      this.$set(this, 'technicianToDelete', {})
      this.$set(this, 'showDeleteModal', false)
    },
    deleteTechnician () {
      const promise = this.delete(this.technicianToDelete)
      promise.then(
        () => {
          setTimeout(() => {
            this.$emit('refresh-list')
            this.closeConfirmModal()
          }, 500)
        }
      )
    }
  }
}
</script>
