<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <b-form>
      <b-form-row>
        <form-thux-horizontal-autocomplete
          :validator="$v.form.technician"
          :label-form="'Technician' | translate"
          class-form="col-lg-6 mb-2 mt-2"
          :disabled="false"
          :label-search="'name'"
          labelColsSm="3"
          labelColsLg="3"
          :value="form.technician ? { id: form.technician, name: `${form.technician_first_name} ${form.technician_last_name}` } : null"
          :options="techniciansListOptions"
          :placeholder="$t('Search by last name')"
          :placeholder-field="form.technician_name"
          @search="onSearchTechnician"
          @select="selectTechnician"
          @clear="clearTechnician"
        />
        <form-thux-horizontal-multiselect
          label-form=""
          label-form-class="mb-2"
          class-form="col-lg-6 my-2"
          label-search="name"
          labelColsSm="0"
          labelColsLg="0"
          :disabled="true"
          :value="form.technician_supplier_role_list"
          :options="form.technician_supplier_role_list || []"
        />
      </b-form-row>
    </b-form>
    <div class="mt-3 f-w-600 d-flex justify-content-end">
      <b-button
        class="mr-3"
        variant="default"
        @click.prevent="formEditable ? cancel() : closeForm()">
        {{ formEditable ? 'Cancel' : 'Close' | translate }}
      </b-button>
      <b-button
        variant="primary"
        v-has-perms="editRolePerm"
        @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
        :disabled="$v.form.$invalid">
        {{ formEditable ? 'Save' : 'Edit' | translate }}
      </b-button>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as YARD_TECHNICIAN_TYPES } from '../../yard-technician/store'

import ThuxDetailPanelMixin from '../../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import TechnicianMixin from '../mixins/TechnicianMixin'

export default {
  name: 'YardTechnicianEdit',
  props: {
    yard: { required: true }
  },
  mixins: [ThuxDetailPanelMixin, TechnicianMixin],
  data () {
    return {
      pageName: 'Technician',
      form: {},
      rolePerm: 'yard_yardtechnician_retrieve',
      editRolePerm: 'yard_yardtechnician_update'
    }
  },
  computed: {
    ...mapGetters({
      ...YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.DETAIL.GETTERS
    })
  },
  methods: {
    ...mapActions({
      ...YARD_TECHNICIAN_TYPES.GENERIC.yard.yardtechnician.DETAIL.ACTIONS
    }),
    setForm () {
      const form = Object.assign({}, this.detail)
      if (form.technician_supplier_role_list) {
        form.technician_supplier_role_list = form.technician_supplier_role_list.map((role) => {
          return {
            id: role.trim(),
            name: role.trim()
          }
        })
      }
      this.$set(this, 'form', form)
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      formData.yard = this.yard
      return formData
    },
    selectTechnician (item) {
      this.setTechniciansList({})
      this.$set(this.form, 'technician', item.id)
      this.$set(this.form, 'technician_first_name', item.first_name)
      this.$set(this.form, 'technician_last_name', item.first_name)
      this.$set(this.form, 'technician_supplier_role_list', item.supplier_role_list)
    },
    clearTechnician () {
      this.setTechniciansList({})
      this.$set(this.form, 'technician', null)
      this.$set(this.form, 'technician_first_name', null)
      this.$set(this.form, 'technician_last_name', null)
      this.$set(this.form, 'technician_supplier_role_list', null)
    }
  },
  validations: {
    form: {
      technician: { required }
    }
  }
}
</script>
